/*
 * @Author: your name
 * @Date: 2021-04-08 17:37:23
 * @LastEditTime: 2021-04-15 21:27:20
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit,
 * @FilePath: \projectc\src\main.js
 */
import Vue from 'vue';
import App from './App.vue';
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router';
import axios from "axios";
import http from './util/https';
import VueWechatTitle from 'vue-wechat-title'
import navigation from "../src/components/navigation/index"

Vue.config.productionTip = false
Vue.prototype.$http = http;
Vue.prototype.$axios = axios;

Vue.filter("workStatus",function(value){
  let status;
  switch (value){
    case 1:
      status = "将入职"
      break;
    case 2:
      status = "已入职"
      break;
    case 3:
      status = "已离职"
      break;
    case 4:
      status = "已拒绝入职"
    break;
    case 5:
      status = "离职确认中"
      break;
    case 6:
      status = "入职确认中"
      break;
    case 7:
      status = "拒绝离职"
      break;
    case 20:
      status = "合作"
    break;
    default:
      status="未知"
  }
  return status
})

Vue.filter("jobStatus",function(value){
  let status;
  switch (value){
    case 1:
      status = "离职-随时到岗"
      break;
    case 2:
      status = "在职-月内到岗"
      break;
    case 3:
      status = "在职-考虑机会"
      break;
    case 4:
      status = "在职，暂不考虑"
    break;
    default:
      status="未知"
  }
  return status
})

Vue.filter("msgType",function(value){
  let status;
  switch (value){
    case 1:
      status = "背调授权"
      break;
    case 2:
      status = "入职授权通知"
      break;
    case 3:
      status = "离职授权通知 "
      break;
    case 4:
      status = "技能认可通知"
    break;
    case 5:
      status = "简历转介绍授权通知"
    break;
    case 6:
      status = "工作评价通知"
    break;
    case 7:
      status = "简历下载"
    break;
    default:
      status="未知"
  }
  return status
})

Vue.filter("date",function(val){
  // console.log(val)
 let arr = val.split(" ")
 let index = arr[0].indexOf("-")
 let str = arr[0].substring(index+1)
  return str
})

Vue.use(Vant);
Vue.use(navigation);

Vue.component("navigation",navigation);

//路由标题
Vue.use(VueWechatTitle);

new Vue({
  render: h => h(App),
  router,
  components:{
    navigation
  }
}).$mount('#app')
