import axios from "axios";
import router from "../router";
import { Toast } from "vant";

/**
 * 跳转到登录页面
 */
const toLogin = () => {
  router.replace({
    path: "/login",
    query: {
      redirect: router.currentRoute.fullPath,
    },
  });
};

/**
 * 判断状态码
 * @param {*} status
 * @param {*} other
 */
const errorHandle = (status) => {
  // 状态码判断
  switch (status) {
    // 302: 未登录状态，跳转登录页
    case 302:
      
      toLogin();
      break;
    // 403 token过期
    // 清除token并跳转登录页
    case 403:
 
      break;
    // 404请求不存在
    case 404:
    
      break;
    default:
      
      
  }
};

// 创建axios实例
// var instance = axios.create({ timeout: 1000 * 200 });
var instance = axios.create();
// 设置post请求头
instance.defaults.headers.post["X-Requested-With"] = "xmlhttprequest";

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
// instance.interceptors.request.use(
//     config => {
//         // 登录流程控制中，根据本地是否存在token判断用户的登录情况
//         // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
//         // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
//         // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
//         const token = store.state.token;
//         token && (config.headers.Authorization = token);
//         return config;
//     },
//     error => Promise.error(error))

// 响应拦截器
instance.interceptors.response.use(
  // 请求成功
  (res) => {
    if (res.data.code == "40003") {
      toLogin();
    }
    return res.status === 200 ? Promise.resolve(res) : Promise.reject(res);
  },
  // 请求失败
  (error) => {
    // if(error.message.includes('timeout')){   // 判断请求异常信息中是否含有超时timeout字符串
    //   Toast({ message: "网络连接超时，请刷新重试" });
    // }
    if (!window.navigator.onLine) {
      Toast({ message: "网络已断开" });
    }
    const { response } = error;
    if(response.status === 500){
      Toast({ message: "服务器出现错误" });
    }
    if (response) {
      // 请求已发出，但是不在2xx的范围
      errorHandle(response.status, response.data.message);
      return Promise.reject(response);
    } else {
      // 处理断网的情况
      // eg:请求超时或断网时，更新state的network状态
      // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
      // 关于断网组件中的刷新重新获取数据，会在断网组件中说明

      return Promise.reject(error);
    }
  }
);
 
export default instance;
