<!--
 * @Author: your name
 * @Date: 2021-04-08 17:37:23
 * @LastEditTime: 2021-04-13 19:09:45
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \projectc\src\App.vue
-->
<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <!-- <div class="nomsg"></div> -->
    <tabble></tabble>
   
  </div>
</template>

<script>
import tabble from "./components/table/index";
export default {
 
  

  name: "app",
  components: {
    tabble,
  },
};
</script>

<style>
.nomsg {
  height: 1.5rem;
}
html {
  min-height: 100%;
  background-color: #f7f7f7;
}
#app {
  font-family: PingFangSC-Regular, PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  background-color: #f7f7f7;
  min-height: 100%;
}
a {
  text-decoration: none;
}
</style>
