<!--
 * @Author: your name
 * @Date: 2021-04-08 17:55:35
 * @LastEditTime: 2021-04-13 16:04:25
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \projectc\src\components\table\index.vue
-->
<template>
  <div id="app_nav" v-show="visible">
    <div class="app_link" exact>
      <router-link to="/home">
        <img
          class="img"
          :src="
            '/home' === $route.path
              ? tabBarImgArr[0].selected
              : tabBarImgArr[0].normal
          "
          alt="职业档案"
        />
        <p :class=" '/home' === $route.path ? 'active': 'noactive'">首页</p>
      </router-link>
    </div>
    <div class="app_link" >
      <router-link to="/message">
        <img
          class="img"
          :src="
            '/message' === $route.path
              ? tabBarImgArr[1].selected
              : tabBarImgArr[1].normal
          "
          alt="职业档案"
        />
        <p :class=" '/message' === $route.path ? 'active': 'noactive'">授权中心</p>
      </router-link>
    </div>
     <div class="app_link">
      <router-link to="/userResume">
        <img
          class="img"
          :src="
            '/userResume' === $route.path
              ? tabBarImgArr[2].selected
              : tabBarImgArr[2].normal
          "
          alt="职业档案"
        />
        <p :class=" '/userResume' === $route.path ? 'active': 'noactive'">我的简历</p>
      </router-link>
    </div>
    <div class="app_link">
      <router-link to="/onlineTag">
        <img
          class="img1"
          :src="
            '/onlineTag' === $route.path
              ? tabBarImgArr[3].selected
              : tabBarImgArr[3].normal
          "
          alt="职业档案"
        />
        <p :class=" '/onlineTag' === $route.path ? 'active': 'noactive'">技能云</p>
      </router-link>
    </div>
    <div class="app_link">
      <router-link to="/user">
        <img
          class="img"
          :src="
            '/user' === $route.path
              ? tabBarImgArr[4].selected
              : tabBarImgArr[4].normal
          "
          alt="我的"
        />
        <p :class=" '/user' === $route.path ? 'active': 'noactive'">我的</p>
      </router-link>
    </div>
    <van-overlay v-if="this.$route.path == '/message' || this.$route.path == '/' " style="z-index:999999"  :show="show" @click="isShow" >
      <div class="wrapper" >
        <div class="block" />
      </div>
    </van-overlay>
    
  </div>

</template>
<script> 
export default {
  data() {
    return {
      zzc:false,
      count:0,
      show:false,
      visible: true,
      token:"",
      tabBarImgArr: [
        //图片切换
        {
          normal: require("../../assets/index1.png"),
          selected: require("../../assets/index.png"),
        },
        {
          normal: require("../../assets/zz.png"),
          selected: require("../../assets/zz2.png"),
        },
        {
          normal: require("../../assets/jl1.png"),
          selected: require("../../assets/jl2.png"),
        },
        {
          normal: require("../../assets/yun22.png"),
          selected: require("../../assets/yun11.png"),
        },
        {
          normal: require("../../assets/user.png"),
          selected: require("../../assets/user1.png"),
        },
      ],
    };
  },
  mounted(){
    console.log(this.$route.path)
    if(this.$route.path == "/message" || this.$route.path == "/"){
      console.log(222)
      this.show = true
    }
   
  },
  methods: {
    isShow(){
       this.show = false
    },
  
   
  },
 
  watch: {
    $route: {
      handler(n) {
        this.visible = !n.meta.flag;
      },
      immediate: true,
    },
  },
};
</script>

<style>
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .block {
    width: 200px;
    height: 120px;
    background-image: url("../../assets/luobo.png");
    background-repeat:no-repeat;
    background-size:3.5rem 2rem;
  }
p{
  margin: 0;
}
a{
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
#fff {
  position: fixed;
  top: 45%;
  right: -5rem;
}
#bbb .kefu6class {
  position: fixed;
  top: 45%;
  right: -5rem;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 0.01rem 0.1rem;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  box-shadow: 0px 0px 0.3rem rgba(73, 73, 73, 0.5);
  font-size: 0.25rem;
  color: #686868;
  transition: right 3s;
}
#bbb .kefu2the {
  right: 0;
}

.kefu6img {
  width: 1rem;
}
#app {
  height: 100%;
}
.marclass {
  height: 0.6rem;
}

/*
  遮罩层
*/
.msk {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
.mymenu {
  background-color: #ffffff;
  margin: 0.3rem;
  padding: 0.3rem;
  border-radius: 10px;
}
.mymenu div {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  justify-content: space-between;
  margin: 0.3rem 0;
}

body {
  font-size: 12px;
  width: 100%;
  height: 100%;
}

.bton {
  width: 90%;
  color: #ffffff;
  background: linear-gradient(to right, #f55613, #fc9e46);
  margin: 0 5%;
  border: none;
  border-radius: 0.8rem;
  font-size: 0.36rem;
  padding: 0.2rem;
  height: 0.96rem;
}
#app_nav {
  width: 100%;
  z-index: 100;
  left: 0;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 1.39rem;
  background-color: #fff;
  box-shadow: 0px -.04rem .08rem 0px #F5F7FA;
}

#app_nav .app_link {
  flex: 1;
  text-align: center;
  font-size: 0.2rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #f44c06;
}
#app_nav .app_link .img {
  width: 0.44rem;
  height: 0.44rem;
}
.img1{
  width:0.55rem;
  height:0.44rem;
}
#app_nav .app_link .yuan {
  background: url(../../assets/ellipse.png);
  display: block;
  width: 1.12rem;
  height: 1.12rem;
  background-size: 100%;
  position: fixed;
  bottom: 0.57rem;
  margin-left: 0.15rem;
}
#app_nav .app_link .yuan .bei {
  margin-top: 0.15rem;
  width: 0.44rem;
  height: 0.44rem;
}
#app_nav .app_link .yuan .diao {
  font-size: 0.2rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
/* #app_nav .app_link p:hover {
  color: #f44c06;
}
#app_nav .app_link p {
  color: #616b80;
} */

.active{
  color: #f44c06;
}
.noactive{
  color: #616b80;
}

</style>